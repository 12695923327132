import React, { lazy, Suspense } from 'react';

const LazyMyOffsets = lazy(() => import('./MyOffsets'));

const MyOffsets = (props: JSX.IntrinsicAttributes & { children?: React.ReactNode; }) => (
  <Suspense fallback={null}>
    <LazyMyOffsets {...props} />
  </Suspense>
);

export default MyOffsets;
