import logo from './images/logo.svg';
import logoText from './images/logo-text.svg';
import iconWallet from './images/icon-menu-wallet.svg';
import iconLeaf from './images/icon-menu-go-green.svg';
import iconOffsetHistory from './images/icon-menu-history.svg';
import iconMyOffsets from './images/icon-menu-my-offsets.svg';
import iconExchange from './images/icon-menu-exchange.svg';
import certImg from "./images/certificate.png";
import printIcon from "./images/icon-print.svg";
import imgGreening from './images/green-img.svg';
import iconZoom from "./images/icon-zoom.svg";
import iconUserGuideWhite from "./images/icon-user-guide-white.svg";
import iconUserGuideBlue from "./images/icon-user-guide-blue.svg";
import './App.css';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  NavLink,
  Redirect,
  useHistory
} from "react-router-dom";
import OffsetingDapp from './components/OffsetingDapp/OffsetingDapp.lazy';
import Green from './components/Green/Green.lazy';
import { useState } from 'react';
import MyOffsets from './components/MyOffsets/MyOffsets.lazy';
import Disconnect from './components/Disconnect/Disconnect.lazy';
import OffsetHistory from './components/OffsetHistory/OffsetHistory.lazy';
import { useCookies } from 'react-cookie';
import WalletFloating from './components/Wallet/WalletFloating.lazy';
import Tutorial from './components/Tutorial/Tutorial.lazy';
import { Popover } from 'react-tiny-popover';

declare const window: any;
function App(props: any): JSX.Element {
  const [cookies, setCookie, removeCookie] = useCookies(['connectedWallet']);
  const [totorialCookie, setTutorialCookie, removeTutorialCookie] = useCookies(['showTutorial']);
  const [menuActive, setMenu] = useState<boolean>(false);
  const [showWallet, setShowWallet] = useState<boolean>(false);
  const [navigateUrl, setNavigateUrl] = useState<string | undefined>(undefined);
  const [letsGoGreenMenuInfo, setLetsGoGreenMenuInfo] = useState<boolean>(false);
  const [offsetHistoryMenuInfo, setOffsetHistoryMenuInfo] = useState<boolean>(false);
  const [myOffsetsMenuInfo, setMyOffsetsMenuInfo] = useState<boolean>(false);
  const [enrexExchangeMenuInfo, setEnrexExchangeMenuInfo] = useState<boolean>(false);
  const [isHover, setHover] = useState<boolean>(false);

  const handleLink = (event: { preventDefault: () => void; stopPropagation: () => void; }) => {
    event.preventDefault();
    event.stopPropagation();
    return false;
  }

  const walletCallback = (connected: boolean) => {
    setNavigateUrl(undefined);
    setShowWallet(false);
  }

  const isUserIconBlue = () =>{
    return isHover && window.innerWidth < 1280;
  }

  return (
    <Router>
      <WalletFloating show={showWallet} setShow={setShowWallet} cl={walletCallback} redirectTo={navigateUrl} ></WalletFloating>
      <Tutorial></Tutorial>
      <div className="root-container">
        <div className='navWrapper'>
          <nav>
            <div className="menu-logo">
              <a href="https://enrex.io"><img className='menu-logo-img' src={logo} /><img className='menu-full-logo-img' src={logoText} /></a>
            </div>
            <div className="menu-nav">
              <ul>
                {!menuActive &&
                  <li>
                    <NavLink
                      to="/home"
                      activeClassName="selected"
                      onClick={(event) => { return menuActive ? handleLink(event) : null; }}
                      onMouseEnter={() => setLetsGoGreenMenuInfo(true)}
                      onMouseLeave={() => setLetsGoGreenMenuInfo(false)}>
                      <div className="menu-item-icon"><img src={iconLeaf} /></div>
                      <div className='menuName'>Let's Go Green</div>
                    </NavLink >
                  </li>
                }
                <li>
                  <NavLink to="/offsetHistory" activeClassName="selected"
                    onMouseEnter={() => setOffsetHistoryMenuInfo(true)}
                    onMouseLeave={() => setOffsetHistoryMenuInfo(false)}>
                    <div className="menu-item-icon"><img src={iconOffsetHistory} /></div>
                    <div className='menuName'>Offset History</div>
                  </NavLink >
                </li>
                <li>
                  <NavLink
                    to="/myOffsets"
                    activeClassName="selected"
                    onClick={(event) => { if (!cookies?.connectedWallet?.address) { setNavigateUrl("/myOffsets"); setShowWallet(true); return handleLink(event); } return null; }}
                    onMouseEnter={() => setMyOffsetsMenuInfo(true)}
                    onMouseLeave={() => setMyOffsetsMenuInfo(false)}>
                    <div className="menu-item-icon"><img src={iconMyOffsets} /></div>
                    <div className='menuName'>My Offsets</div>
                  </NavLink >
                </li>
                <li aria-disabled="true">
                  <a onMouseEnter={() => setEnrexExchangeMenuInfo(true)}
                    onMouseLeave={() => setEnrexExchangeMenuInfo(false)}>
                    <div className="menu-item-icon"><img src={iconExchange} /></div>
                    <div className='menuName'>Enrex Exchange</div>
                  </a>
                </li>
              </ul>
            </div>
            <div className="menu-footer">
              <ul>
                <li>
                  <a className='footer-menu-item' onClick={() => setTutorialCookie('showTutorial', null)} onMouseEnter={() => {setHover(true)}} onMouseLeave={() => {setHover(false)}}>
                    <div className="menu-item-icon"><img src={isUserIconBlue() ? iconUserGuideBlue : iconUserGuideWhite} /></div>
                    <div className='menuName'>User Guide</div>
                  </a>
                </li>
              </ul>
              {/* {cookies.connectedWallet &&
                <button className='footer-menu-item' onClick={() => {
                  walletServiceProvider(cookies.connectedWallet.walletType).disconnectWallet();
                  removeCookie('connectedWallet')
                }
                }>
                  <div className="menu-item-icon" ><img src={iconWallet} /> </div>
                  Disconnect Wallet
                </button>
              } */}
            </div>
          </nav>
        </div>
        <div className="content-container">
          <Switch>
            <Route path="/home">
              <OffsetingDapp showWallet={showWallet} setShowWallet={setShowWallet}></OffsetingDapp>
            </Route>
            <Route path="/disconnect">
              <Disconnect></Disconnect>
            </Route>
            <Route exact path="/offseting-dapp/green">
              <Green />
            </Route>
            <Route path="/offseting-dapp">
              <OffsetingDapp showWallet={showWallet} setShowWallet={setShowWallet}></OffsetingDapp>
            </Route>
            <Route path="/my-offsets/:id/cert">
              <div className="cert-detail closable">
                <img src={certImg} alt="cert" />
                <div className="actions-container">
                  <div>
                    <NavLink to="/my-offsets" activeClassName="selected" onClick={(event) => { return !menuActive ? handleLink(event) : null; }}>
                      <div className="close-button"></div>
                    </NavLink >
                  </div>
                  <div className="pagination">
                    <button className="prev" disabled={true}><span>&lt;</span></button>
                    1/7
                    <button className="next" disabled={true}><span>&gt;</span></button>
                  </div>
                  <div className="print">
                    <button disabled={true}><img src={printIcon} className="btn-icon" />print</button>
                  </div>
                </div>
              </div>
            </Route>
            <Route path="/my-offsets/:id">
              <MyOffsets></MyOffsets>
            </Route>
            <Route path="/myOffsets">
              <MyOffsets></MyOffsets>
            </Route>
            <Route path="/offsetHistory">
              <OffsetHistory></OffsetHistory>
            </Route>
            <Route
              exact
              path="/"
              render={() => {
                return (
                  <Redirect to="/home" />
                )
              }}
            />
          </Switch>
        </div>
      </div>
    </Router >
  );
}

export default App;
function useNavigate() {
  throw new Error('Function not implemented.');
}

