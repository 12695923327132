import React, { lazy, Suspense } from 'react';

const LazyGreen = lazy(() => import('./Green'));

const Green = (props: JSX.IntrinsicAttributes & { children?: React.ReactNode; }) => (
  <Suspense fallback={null}>
    <LazyGreen {...props} />
  </Suspense>
);

export default Green;
