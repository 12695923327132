import React, { lazy, Suspense } from 'react';

const LazyWalletFloating = lazy(() => import('./WalletFloating'));

const WalletFloating = (props: JSX.IntrinsicAttributes & { children?: React.ReactNode; show?: boolean, setShow?: Function, cl?: Function, redirectTo?: string }) => (
  <Suspense fallback={null}>
    <LazyWalletFloating {...props} />
  </Suspense>
);

export default WalletFloating;
