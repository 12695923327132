import React, { lazy, Suspense } from 'react';

const LazyTutorial = lazy(() => import('./Tutorial'));

const Tutorial = (props: JSX.IntrinsicAttributes) => (
  <Suspense fallback={null}>
    <LazyTutorial/>
  </Suspense>
);

export default Tutorial;