import React, { lazy, Suspense } from 'react';

const LazyOffsetingDapp = lazy(() => import('./OffsetingDapp'));

const OffsetingDapp = (props: JSX.IntrinsicAttributes & { children?: React.ReactNode; showWallet?: boolean, setShowWallet?: Function}) => (
  <Suspense fallback={null}>
    <LazyOffsetingDapp {...props} />
  </Suspense>
);

export default OffsetingDapp;
